exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-gr-calendargr-js": () => import("./../../../src/pages/gr/calendargr.js" /* webpackChunkName: "component---src-pages-gr-calendargr-js" */),
  "component---src-pages-gr-contactgr-js": () => import("./../../../src/pages/gr/contactgr.js" /* webpackChunkName: "component---src-pages-gr-contactgr-js" */),
  "component---src-pages-gr-coursesgr-js": () => import("./../../../src/pages/gr/coursesgr.js" /* webpackChunkName: "component---src-pages-gr-coursesgr-js" */),
  "component---src-pages-gr-home-js": () => import("./../../../src/pages/gr/home.js" /* webpackChunkName: "component---src-pages-gr-home-js" */),
  "component---src-pages-gr-toursgr-js": () => import("./../../../src/pages/gr/toursgr.js" /* webpackChunkName: "component---src-pages-gr-toursgr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tours-js": () => import("./../../../src/pages/tours.js" /* webpackChunkName: "component---src-pages-tours-js" */),
  "component---src-templates-news-category-js": () => import("./../../../src/templates/news-category.js" /* webpackChunkName: "component---src-templates-news-category-js" */),
  "component---src-templates-news-categorygr-js": () => import("./../../../src/templates/news-categorygr.js" /* webpackChunkName: "component---src-templates-news-categorygr-js" */),
  "component---src-templates-news-listing-js": () => import("./../../../src/templates/news-listing.js" /* webpackChunkName: "component---src-templates-news-listing-js" */),
  "component---src-templates-news-listinggr-js": () => import("./../../../src/templates/news-listinggr.js" /* webpackChunkName: "component---src-templates-news-listinggr-js" */),
  "component---src-templates-news-tag-js": () => import("./../../../src/templates/news-tag.js" /* webpackChunkName: "component---src-templates-news-tag-js" */),
  "component---src-templates-news-taggr-js": () => import("./../../../src/templates/news-taggr.js" /* webpackChunkName: "component---src-templates-news-taggr-js" */),
  "component---src-templates-projects-listing-js": () => import("./../../../src/templates/projects-listing.js" /* webpackChunkName: "component---src-templates-projects-listing-js" */),
  "component---src-templates-projects-listinggr-js": () => import("./../../../src/templates/projects-listinggr.js" /* webpackChunkName: "component---src-templates-projects-listinggr-js" */),
  "component---src-templates-single-course-js": () => import("./../../../src/templates/single-course.js" /* webpackChunkName: "component---src-templates-single-course-js" */),
  "component---src-templates-single-news-js": () => import("./../../../src/templates/single-news.js" /* webpackChunkName: "component---src-templates-single-news-js" */),
  "component---src-templates-single-newsgr-js": () => import("./../../../src/templates/single-newsgr.js" /* webpackChunkName: "component---src-templates-single-newsgr-js" */),
  "component---src-templates-single-project-js": () => import("./../../../src/templates/single-project.js" /* webpackChunkName: "component---src-templates-single-project-js" */),
  "component---src-templates-single-projectgr-js": () => import("./../../../src/templates/single-projectgr.js" /* webpackChunkName: "component---src-templates-single-projectgr-js" */),
  "component---src-templates-single-scheduled-tourgr-js": () => import("./../../../src/templates/single-scheduled-tourgr.js" /* webpackChunkName: "component---src-templates-single-scheduled-tourgr-js" */),
  "component---src-templates-single-tour-js": () => import("./../../../src/templates/single-tour.js" /* webpackChunkName: "component---src-templates-single-tour-js" */),
  "component---src-templates-single-tourgr-js": () => import("./../../../src/templates/single-tourgr.js" /* webpackChunkName: "component---src-templates-single-tourgr-js" */)
}

